import type { IData, IPageData } from '~/typings/types/data.types';
import type { IComponent, IComponentAsync, IComponentProps } from '~/typings/types/component.types';
import type { TWebsiteConfig } from '~/typings/types/website-config.types';
import findDuplicateUUIDs from '~/utils/arrays/findDuplicateUuids';
import isDataExist from '~/utils/validators/isDataExist';
import isGridValid from '~/utils/validators/isGridValid';
import { EErrorType, errorHandler } from '~/utils/errorHandlers/customErrorHandler';

export interface IUseGetData<Component> {
  /**
   * @description Базовый URL статики.
   */
  baseUrl: string;

  /**
   * @description Базовый URL для вызовов API.
   */
  baseApiUrl: string;

  /**
   * @description Функция получения данных для компонента.
   * @param {IComponentProps} props - Props компонента секции.
   * @returns {IComponent<Component>}
   */
  getComponent: (props: IComponentProps<Component>) => IComponent<Component>;

  /**
   * @description Функция получения данных для страницы.
   */
  getPage: () => IPageData;

  /**
   * @description Функция для рендера организмов.
   * @returns {Promise<IComponentAsync[] | null>}
   */
  getPageAsyncComponents: () => Promise<IComponentAsync[] | null>;
}

/**
 * @description Composable для получения данных.
 * @returns {IUseGetData<Component>}
 */
export default function useGetData<Component>(): IUseGetData<Component> {
  const runtimeConfig = useRuntimeConfig();

  const websiteConfig = useState<TWebsiteConfig>('config');
  const data = useState<IData<Component> | null>('data');

  const baseUrl = runtimeConfig.app.cdnURL ? runtimeConfig.app.cdnURL : runtimeConfig.app.baseURL;
  const baseApiUrl = import.meta.server
    ? runtimeConfig.API_SERVER_URL
    : runtimeConfig.public.API_CLIENT_URL;

  function getComponent(props: IComponentProps<Component>) {
    isDataExist(data.value);

    if (!props.data) {
      return errorHandler.throwError(
        EErrorType.NUXT_SERVER_ERROR,
        'Props data is empty.',
        `В представлении "${props.viewName}" нет данных, props data - пустой.`,
      );
    }

    //TODO: Это нужно убрать при рефакторинге процесса работы с mock. Проблема появляется из-за того, что frontend генерит JSON файлы для mock руками. Приходится генерить и уникальные UUID для компонентов.
    if (websiteConfig.value.modes.isDevelopment) {
      const duplicatedUuid = findDuplicateUUIDs<Component>(data.value);
      if (duplicatedUuid.length) {
        return errorHandler.throwError(
          EErrorType.NUXT_SERVER_ERROR,
          `Обнаружено дублирование ${duplicatedUuid.length} UUID компонентов.`,
          `Дублированные компоненты: ${JSON.stringify(duplicatedUuid, null, 2)} `,
        );
      }
    }

    if (!props.data.uuid) {
      return errorHandler.throwError(
        EErrorType.NUXT_SERVER_ERROR,
        ' The uuid is not specified in the view.',
        `Не указан uuid в представлении "${props.viewName}".`,
      );
    }

    const component = data.value.grid.find(
      (cmp: IComponent<Component>) => cmp.uuid === props.data?.uuid,
    );

    if (!component) {
      return errorHandler.throwError(
        EErrorType.NUXT_SERVER_ERROR,
        'The component with the specified uuid was not found',
        `Компонент с указанным uuid "${props.data.uuid}" не найден`,
      );
    }

    return component;
  }

  function getPage() {
    isDataExist(data.value);

    return data.value.page;
  }

  async function getPageAsyncComponents(): Promise<IComponentAsync[] | null> {
    if (!data.value) return null;
    isGridValid(data.value);

    return await Promise.all(
      data.value.grid.map(async (item: IComponent<unknown>) => {
        return {
          data: item,
          component: defineAsyncComponent(
            async () =>
              await import(
                `~/components/O/${item.attributes['component-group']}/${item.attributes['component-modifier']}/${item.attributes['component-view']}.vue`
              ),
          ),
        };
      }),
    );
  }

  return {
    baseUrl,
    baseApiUrl,
    getComponent,
    getPage,
    getPageAsyncComponents,
  };
}
