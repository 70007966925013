import type { IData } from '~/typings/types/data.types';
import type { IComponent } from '~/typings/types/component.types';

/**
 * @description Функция возвращает объекты компонентов из сетки Grid, в которых UUID задублированны.
 * @param {IData<Component>} data
 * @returns {any[]}
 */
export default function findDuplicateUUIDs<Component>(data: IData<Component>) {
  const uuidMap: Record<string, Array<IComponent<Component>>> = {};
  const duplicates: any[] = [];

  data.grid.forEach((item) => {
    if (uuidMap[item.uuid]) {
      uuidMap[item.uuid].push(item);
    } else {
      uuidMap[item.uuid] = [item];
    }
  });

  for (const uuid in uuidMap) {
    if (uuidMap[uuid].length > 1) {
      duplicates.push(...uuidMap[uuid]);
    }
  }

  return duplicates;
}
