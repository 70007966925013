import type { IData } from '~/typings/types/data.types';
import { EErrorType, errorHandler } from '~/utils/errorHandlers/customErrorHandler';

/**
 * @description Функция проверяет data на falsy значение. Ключевое слово asserts сообщает TypeScript, что после вызова
 * этой функции, объект данных будет иметь тип IData<Component>. Это позволяет IDE убедиться, что data существует и
 * имеет правильный тип в последующем коде.
 *
 * @param {IData<Component> | null} data
 */
export default function isDataExist<Component>(
  data: IData<Component> | null,
): asserts data is IData<Component> {
  if (data) return;

  return errorHandler.throwError(
    EErrorType.NUXT_SERVER_ERROR,
    'No data for rendering page.',
    `Нет данных для рендера страницы. Структура данных: ${JSON.stringify(data)}`,
  );
}
