import throttle from '@ice-products-ui/vue-library/Throttle';
import useBody from '~/composables/dom/useBody';

/**
 * @description Use browser scroll composable
 * @param {number} offset - Offset Y in pixels
 * @param {"header-scrolled"} className - Classname for scrolled state
 * @param {number} throttleTime - Throttled milliseconds
 */
export default function useScroll(
  offset: number,
  className: 'header-scrolled' | 'button-up',
  throttleTime: number,
) {
  const { toggleBodyClass } = useBody();

  const bodyScroll = (): void => {
    const scroll = window.scrollY;

    if (scroll > offset) {
      toggleBodyClass('add', className);
    } else {
      toggleBodyClass('remove', className);
    }
  };

  const bodyScrollLink = throttle(bodyScroll, throttleTime);

  onMounted(() => {
    bodyScroll();
    window.addEventListener('scroll', bodyScrollLink);
  });

  onUnmounted(() => {
    window.removeEventListener('scroll', bodyScrollLink);
  });
}
