import type { IData } from '~/typings/types/data.types';
import { EErrorType, errorHandler } from '~/utils/errorHandlers/customErrorHandler';

/**
 * @description Функция проверяет тип полученного Grid для дальнейшего корректного рендера компонентов.
 * @param {IData<Component>} data
 */
export default function isGridValid<Component>(data: IData<Component>): void {
  if (Array.isArray(data.grid)) return;

  return errorHandler.throwError(
    EErrorType.NUXT_SERVER_ERROR,
    'Grid is not valid.',
    `
      Структура данных: ${JSON.stringify(data)}
      \n
      \n
      Структура объекта сетки: ${JSON.stringify(data.grid)}
    `,
  );
}
